import React, { useCallback, useMemo, useRef, useState } from "react"
import Layout from "~/components/Layout"
import Button from "~/components/reusable/Button"
import Wrapper from "~/components/reusable/Wrapper"
import Map from "~/components/Map"
import useAccountManagers from "~/hooks/useAccountManagers"
import useRegions from "~/hooks/useRegions"
import { convertToSlug, postcodePrefix } from "~/utils/helpers"
import AccountManagerCard from "~/components/AccountManagerCard"
import ContactUsForm from "~/components/ContactUsForm"
import Spacer from "~/components/reusable/svgs/Spacer"
import useTemplateContent from "~/hooks/useTemplateContent"
import parse from "html-react-parser"
import {
  FaCheckSquare,
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa"
import Seo from "~/components/SEO"
import clsx from "clsx"
import { LookupTable } from "~/utils/types"
import { toast } from "react-toastify"
import useBusinessInformation from "~/hooks/useBusinessInfo"

const ContactUs = () => {
  const accountManagers = useAccountManagers()
  const regions = useRegions()
  const [selectedRegion, setSelectedRegion] = useState("")
  const [postcodeQuery, setPostcodeQuery] = useState("")
  const [clickedSearch, setClickedSearch] = useState(false)
  const postcodeInput = useRef<HTMLInputElement>(null)
  const mapRef = useRef<HTMLElement>(null)
  const content = useTemplateContent("contactUs")
  const businessInfo = useBusinessInformation()

  // find the region that matches the postcode query
  const postcodeMatchedRegion = regions?.find(({ postcodes }: any) => {
    const prefix = postcodePrefix(postcodeQuery)
    if (!prefix) return false

    return postcodes
      ?.map((postcode: string) => postcode.toLowerCase())
      .includes(prefix.toLowerCase())
  })

  // find the account manager that matches the selected region or postcodeMatchedRegion
  const matchedAccountManager = useMemo(
    () =>
      (selectedRegion || (postcodeQuery && clickedSearch)) &&
      accountManagers?.find(
        ({ region }: any) =>
          (selectedRegion &&
            region?.toLowerCase() === convertToSlug(selectedRegion)) ||
          (postcodeMatchedRegion &&
            region?.toLowerCase() ===
              convertToSlug(postcodeMatchedRegion?.name))
      ),
    [selectedRegion, postcodeQuery, clickedSearch]
  )

  const handleRegionChange = (region: string) => {
    setPostcodeQuery("")
    setSelectedRegion(region)
    if (postcodeInput.current) postcodeInput.current.value = ""
  }

  const handleSearchClick = useCallback(() => {
    setPostcodeQuery(postcodeInput.current?.value || "")

    if (!postcodeInput.current?.value) {
      toast("Please enter a postcode", {
        type: "error",
        hideProgressBar: true,
        autoClose: 2000,
      })
      return
    }

    setClickedSearch(true)

    // if postcodeQuery is empty, set selectedRegion to empty string
    if (!postcodeQuery) {
      setSelectedRegion("")
    }
    // scroll to mapRef
    if (mapRef.current) {
      mapRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }, [postcodeQuery, mapRef])

  // check if window is defined
  const isBrowser = typeof window !== "undefined"

  const regionMarkers: LookupTable = {
    "East Anglia": "top-[73%] left-[87%]",
    "Midlands and North Wales": "top-[70%] left-[62%]",
    "North England inc Isle of Man": "top-[58%] left-[68%]",
    "Scotland and NI": "top-[37%] left-[42%]",
    "South East England": "top-[88%] left-[79%]",
    "South West and South Wales": "top-[80%] left-[51%]",
  }

  return (
    <Layout path="/contact-us/">
      <Seo title={content.meta.title} description={content.meta.description} />
      <Wrapper>
        <h1 className="flex-set-0-0 mx-auto mb-6 mt-10 basis-full px-3 text-h2 font-bold md:mt-16 md:text-h1 lg:basis-5/6">
          Contact us
        </h1>
      </Wrapper>
      <Spacer top={`white`} bottom={`yellow`} />
      <div className={`map bg-theme-yellow`}>
        <Wrapper>
          <div
            className={`flex-set-0-0 vs-shadow relative z-[2] mx-auto -mt-14 basis-almost-full flex-wrap rounded-[32px] bg-theme-yellow lg:basis-almost-5/6`}
          >
            <div
              className={`flex flex-wrap items-end justify-start p-8 md:p-16`}
            >
              <h3
                className={`flex-set-0-0 md:flex-set-1-1 mb-6 basis-full text-h4 font-bold md:mb-0 md:basis-[0%] md:text-h3 md:leading-[40px]`}
              >
                Find your Account Manager
              </h3>
              <div
                className={`flex-set-0-0 mb-4 basis-full md:ml-6 md:mb-0 md:basis-[40%]`}
              >
                <label htmlFor="postcode" className={`mb-2 block text-caption`}>
                  Postcode <span className={`text-theme-orange`}>*</span>
                </label>
                <input
                  ref={postcodeInput}
                  id={"postcode"}
                  type={"text"}
                  className={`vs-shadow-light block w-full rounded-2xl border-none bg-theme-white p-5 text-caption text-theme-gray-mid`}
                  defaultValue={postcodeQuery}
                  onKeyDown={e => {
                    if (e.keyCode === 13 || e.key === "Enter") {
                      handleSearchClick()
                    }
                  }}
                />
              </div>
              <div
                className={`flex-set-0-0 ml-auto md:ml-6 [&_.brew-button]:mr-0 [&_.brew-button]:mb-0`}
              >
                <Button
                  buttonStyle={`primary`}
                  type="button"
                  text="Search"
                  buttonColor="orange"
                  onClick={handleSearchClick}
                />
              </div>
            </div>
          </div>

          <div
            className={`flex-set-0-0 relative mb-11 basis-full px-3 [&_.account-manager_>_img]:hidden [&_.account-manager]:md:absolute [&_.account-manager]:md:right-[62%] [&_.account-manager]:md:top-1/2 [&_.account-manager]:md:w-[295px] [&_.account-manager]:md:-translate-y-1/4`}
          >
            <div
              className={`relative mx-auto -mt-10 w-full max-w-[700px] text-center md:-mt-20 lg:mt-[-153px]`}
            >
              {regions.map((region: any) => (
                <div
                  key={region.id}
                  className={clsx(
                    `vs-shadow absolute -translate-y-1/2 -translate-x-1/2 cursor-pointer rounded-full bg-theme-white p-1 sm:p-[6px]`,
                    regionMarkers[region.name]
                  )}
                  onClick={() => handleRegionChange(region.name)}
                >
                  <h3
                    className={`h-8 w-8 rounded-full text-[0px] shadow-inner sm:h-12 sm:w-12 ${
                      (selectedRegion ||
                        (clickedSearch && postcodeMatchedRegion?.name)) ===
                      region.name
                        ? "bg-theme-green-light"
                        : "bg-theme-orange"
                    }`}
                  >
                    {region.name}
                  </h3>
                </div>
              ))}
              <div ref={mapRef} style={{ scrollMarginTop: "-128px" }}>
                <Map />
              </div>
            </div>
            <AccountManagerCard
              accountManager={matchedAccountManager}
              region={selectedRegion || postcodeMatchedRegion?.name}
            />
          </div>
        </Wrapper>
      </div>
      <div className={`bg-theme-yellow`}>
        <div className={`mb-[140px] bg-theme-white pt-10 md:pt-[72px]`}>
          <Wrapper>
            <div
              className={`flex-set-0-0 basis-full px-3 md:basis-1/2 md:pt-[60px] lg:mx-1/12 lg:basis-1/3`}
            >
              <h4 className={`mb-8 text-h3 md:text-h4 md:leading-8`}>
                {parse(content.title)}
              </h4>
              <p className={`mb-8 text-normal leading-8`}>
                {parse(content.text)}
              </p>
              {businessInfo?.telephone && (
                <p className={`mb-6 text-normal`}>
                  <i
                    className={`mr-2 inline-block h-6 w-6 align-middle text-theme-orange`}
                  >
                    <FaPhoneAlt />
                  </i>
                  <a className={`font-bold`}>{businessInfo.telephone}</a>
                </p>
              )}
              {businessInfo?.email && (
                <p className={`mb-6 text-normal`}>
                  <i
                    className={`mr-2 inline-block h-6 w-6 align-middle text-theme-orange`}
                  >
                    <FaEnvelope />
                  </i>
                  <a className={`font-bold`}>{businessInfo.email}</a>
                </p>
              )}
              <p className={`mb-6 text-normal`}>
                <i
                  className={`mr-2 inline-block h-6 w-6 align-middle text-theme-orange`}
                >
                  <FaMapMarkerAlt />
                </i>
                <span
                  className={`inline-block w-[calc(100%_-_40px)] align-top`}
                >
                  <strong>{businessInfo?.name},</strong>{" "}
                  {businessInfo?.address1}, {businessInfo?.address2},{" "}
                  {businessInfo?.town}, {businessInfo?.region},{" "}
                  {businessInfo?.postcode}
                </span>
              </p>
            </div>
            <div
              className={`flex-set-0-0 vs-shadow mx-3 mb-[-72px] basis-almost-full overflow-hidden rounded-[32px] bg-theme-yellow p-12 md:basis-almost-1/2 lg:basis-almost-5/12 [&_.brew-button]:mr-0 [&_.brew-button]:ml-auto [&_.brew-button]:block`}
            >
              <h3 className={`mb-5 text-h4 font-bold md:text-h3`}>
                Get in touch
              </h3>
              {isBrowser && <ContactUsForm />}
            </div>
          </Wrapper>
        </div>
        <Spacer top={`yellow`} bottom={`black`} />
      </div>
    </Layout>
  )
}

export default ContactUs
