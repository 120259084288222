import { graphql, useStaticQuery } from "gatsby"

const useBusinessInformation = () => {
  const data = useStaticQuery(graphql`
    query businessInformation {
      wp {
        businessInformation {
          LocationData {
            name
            address1
            address2
            town
            region
            postcode
            telephone
            email
          }
        }
      }
    }
  `)

  return data?.wp?.businessInformation?.LocationData
}

export default useBusinessInformation
