import { graphql, useStaticQuery } from "gatsby"

const useAccountManagers = () => {
  const data = useStaticQuery(graphql`
    query getAccountManagers {
      allWpAccountManager {
        edges {
          node {
            id
            featuredImage {
              node {
                mediaItemUrl
              }
            }
            details {
              forename
              surname
              telephone
              emailAddress
              region
            }
          }
        }
      }
    }
  `)

  return data?.allWpAccountManager?.edges?.map((edge: any) => ({
    id: edge.node.id,
    thumbnail: edge.node.featuredImage?.node?.mediaItemUrl,
    ...edge.node.details,
  }))
}

export default useAccountManagers
